import {createGlobalState} from "react-global-hooks";
import Modal from "react-bootstrap/Modal";
import React, {useEffect, useMemo, useState} from "react";
import {toast} from 'react-toastify';
import Button from "react-bootstrap/Button";
import {FaTrash} from "react-icons/fa";
import NoData from "@components/Indicators/NoData";
import moment from "moment";
import {cancelAll, cancelQueue, IDefNotificationQueue, listForMe} from "@hornet-api/defNotificationQueue";
import {alertApiErrors} from "@common/errors";
import {refreshQueueListState} from "@components/NotificationSidebar/globalState";
import {DateFormats} from "@interfaces/DateFormats";
import {formatDate} from "@common/basic";
import {getIconByTrigger} from "@admin-ui/pages/EmailTemplatePage/components/NotificationIconMap";
import FontAwesomeIcon from "@admin/components/icons/FontAwesomeIcon";
import {EmailTriggerEnum} from "@interfaces/GeneratedEnums";

/*
 * Utility functions
 */
const defNotificationQueueListModalState = createGlobalState<boolean>(false);

export const openDefNotificationQueueListModal = () => {
  defNotificationQueueListModalState.set(true);
};

const CLOSED_N_Q_IDS = 'CLOSED_NOTIFICATION_QUEUE_IDS';

const getClosedNotificationQueueIds = () => {
  return (window.localStorage.getItem(CLOSED_N_Q_IDS) || '').split(',')
}

const addToRemovedNotificationQueueIds = (id: string) => {
  const closedIds = getClosedNotificationQueueIds();
  if (closedIds.includes(id)) {
    return;
  }
  closedIds.push(id);
  window.localStorage.setItem(CLOSED_N_Q_IDS, closedIds.join(','));
}

const updateClosedNotificationQueueIds = (serverIds: string[]) => {
  const closedIds = getClosedNotificationQueueIds()
    .filter((closedId) => serverIds.some(id => closedId === id));
  window.localStorage.setItem(CLOSED_N_Q_IDS, closedIds.join(','));

  return closedIds;
}

const TriggerIcon = ({emailTrigger}: { emailTrigger: keyof typeof EmailTriggerEnum }) => {
  const icon = useMemo(() => {
    return getIconByTrigger(emailTrigger)
  }, [emailTrigger]);

  if (typeof icon === 'string') {
    return (
      <FontAwesomeIcon icon={icon}/>
    );
  }
  return icon || null;
}

/*
 * Component
 */
const DefNotificationQueueListModal = () => {
  const [modalState, setModalState] = defNotificationQueueListModalState.use();
  const [defNotificationQueueList, setDefNotificationQueueList] = useState<IDefNotificationQueue[] | null>(null);
  const toggleQueueList = refreshQueueListState.useValue();

  const handleClose = () => {
    setModalState(false);
  };

  const getMyDefNotificationQueueList = () => {
    listForMe()
      .then(setDefNotificationQueueList)
      .catch((reason) => {
        console.error(reason)
      });
  }

  useEffect(() => {
    const unsubscribe = toast.onChange((payload) => {
      if (payload.status === 'removed') {
        const closedId = `${(payload.data as IDefNotificationQueue).id}`;
        addToRemovedNotificationQueueIds(closedId)
      }
    });
    getMyDefNotificationQueueList();
    return unsubscribe
  }, [toggleQueueList]);

  useEffect(() => {
    //  - get list of IDs from server as serverIds having time-left greater than 0
    //  - get list of IDs from localStorage as closedIds
    //  - remove all the additional closedIds compared to serverIds
    //  - show Popup for all the serverIds which are not in closedIds
    //  - onClose of the toast, add it to the closedIds in localStorage

    if (!defNotificationQueueList) return;

    const closedIds = updateClosedNotificationQueueIds(defNotificationQueueList.map(({id}) => `${id}`));

    defNotificationQueueList.forEach((defNotificationQueue) => {
      const thisId = `${defNotificationQueue.id}`;
      if (closedIds.some(closedId => closedId === thisId)) return;

      const timeLeft = defNotificationQueue.timeLeft;
      if (timeLeft <= 0) {
        addToRemovedNotificationQueueIds(thisId);
        return;
      }

      // toast prevents duplicates based on the toastId
      // it has to be unique through-out the application
      toast.warn(getDefNotificationQueueMessage(defNotificationQueue), {
        toastId: getToastId(defNotificationQueue.id),
        autoClose: timeLeft,
        pauseOnHover: false,
        data: defNotificationQueue,
        closeOnClick: false,
        pauseOnFocusLoss: false
      });
    })
  }, [defNotificationQueueList])

  const getToastId = (id: number) => (
    `notification-queue-${id}`
  );

  const handleCancelNotificationById = (id: number) => {
    cancelQueue(id).catch(alertApiErrors).finally(getMyDefNotificationQueueList);
  }

  const handleCancelAllNotification = () => {
    cancelAll().catch(alertApiErrors).finally(getMyDefNotificationQueueList);
  }

  const getDefNotificationQueueMessage = ({id, emailTrigger, triggerAction}: IDefNotificationQueue) => {
    return (
      <>
        <strong>Notification Pending <TriggerIcon emailTrigger={emailTrigger}/>
        </strong>
        <div style={{fontSize: '.85rem'}}>
          {triggerAction}
        </div>
        <Button
          className={'text-success pl-0'}
          variant={'link'}
          size={'sm'}
          onClick={() => {
            toast.dismiss(getToastId(id));
          }}
        >OK</Button>
        <Button
          className={'text-danger'}
          variant={'link'}
          size={'sm'}
          onClick={() => {
            handleCancelNotificationById(id);
            toast.dismiss(getToastId(id));
          }}
        >Don't Send</Button>
      </>
    )
  }

  return (
    <Modal
      show={modalState}
      onHide={handleClose}
      id={'DefNotificationQueueListModal'}
      size={'lg'}
    >
      <Modal.Header closeButton>
        <Modal.Title>Deferred Notification Queue</Modal.Title>
      </Modal.Header>
      <Modal.Body>

        <div className="table-responsive">
          <table className="table table-striped table-hover">
            <thead className="back_table_color">
            <tr>
              <th>ID</th>
              <th>Trigger Action</th>
              <th>Run At</th>
              <th>Action</th>
            </tr>
            </thead>
            <tbody>
            {
              defNotificationQueueList?.map(({id, triggerAction, runAt}, index) => {
                return (
                  <tr key={index}>
                    <td>{id}</td>
                    <td>{triggerAction}</td>
                    <td>{formatDate(moment(runAt), DateFormats.FULL)}</td>
                    <td><FaTrash onClick={() => {
                      handleCancelNotificationById(id);
                    }}/></td>
                  </tr>
                )
              })
            }
            </tbody>
          </table>
          {!defNotificationQueueList?.length && <NoData/>}
        </div>


      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            handleCancelAllNotification()
          }}
        >Cancel All</Button>
      </Modal.Footer>
    </Modal>
  )
}
export default DefNotificationQueueListModal;