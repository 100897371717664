import axios from 'axios';
import isMaintenanceState from '@state/globalState/isMaintenanceState';
import redirectToState from '@state/globalState/redirectToState';
import {getAccessToken} from "@common/token";

(window as any).isInterceptorSetup = false;

const setupInterceptor = () => {
  //412 status code will redirect
  if (!(window as any).isInterceptorSetup) {

    // Add a request interceptor to every axios request
    axios.interceptors.request.use(config => {
      const token = getAccessToken();
      if (token) {
        config.headers = {
          ...config.headers,
          Authorization: `Bearer ${token}`
        }
      }
      return config;
    }, error => {
      return Promise.reject(error);
    });

    // Add a response interceptor to every axios request
    axios.interceptors.response.use((response) => {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response;
    }, async (error) => {
      const maintenanceErrorCodes = [503, 502];
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      if (error.response) {
        // handle logout on application restart
        if (error.response.status === 440) {
          window.location.href = '/logout';
        }

        // handle maintenance
        if (maintenanceErrorCodes.includes(error.response.status)) {
          // site under maintenance
          isMaintenanceState.set(true);
        }

        // handle redirects to profile
        if (error.response.status === 412) {
          const data = error.response.data;
          const reasons = data.reasons;
          redirectToState.set('/userContact/profile');
        }

        // handle redirect to verify OTP
        if (error.response.status === 428) {
          const data = error.response.data;
          const reasons = data.reasons;
          redirectToState.set('/login/verify');
        }
      }

      // report errors to log so we can see them in rollbar
      try {
        console.log({
          error: error?.response?.status || error,
          data: error?.response?.data,
          location: window.location,
          errorType: typeof error?.toJSON,
          json: typeof error?.toJSON === 'function' ? error?.toJSON() : error
        });
      } catch(e) {
        console.log(e)
      }

      return Promise.reject(error);
    });
    (window as any).isInterceptorSetup = true;
  }
};

export default setupInterceptor;
