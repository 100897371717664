import React, {JSXElementConstructor, ReactElement, ReactNode, useMemo, useState} from 'react';
import classNames from 'classnames';
import Form from "react-bootstrap/Form";
import {FormControlElement} from "@components/forms/react-hook-form-bootstrap/SmartInput";
import {FaExclamation, FaTimes} from "react-icons/fa";
import {InputGroup} from "react-bootstrap";

type Props = {
  className?: string,
  name?: string,
  value: string,
  placeholder?: string,
  error?: string | null;
  required?: boolean,
  disabled?: boolean,
  onChange?: (e: React.ChangeEvent<FormControlElement>) => void,
  showOnly?: boolean;
  label?: string | ReactElement<any, string | JSXElementConstructor<any>> | undefined
  showClearBtn?: boolean
  description?: ReactNode
}

export const formatPhoneNumber = (value: string | null) => {
  value = value ?? '';
  return `+1 ${value.slice(0, 3)}-${value.slice(3, 6)}-${value.slice(6, 10)}`
}

export const getPhoneDisplayValue = (value: string | null) => {
  value = value ?? '';
  // remove the +1 added by server
  if (value.startsWith('+1 ')) {
    value = value.slice(3);
  }
  value = value.trim().replace(/[^\d]/g, '');
  if (value.length < 1) return value;
  if (value.length < 4) return `(${value}`;
  if (value.length < 7) return `(${value.slice(0, 3)}) ${value.slice(3)}`;
  return `(${value.slice(0, 3)}) ${value.slice(3, 6)}-${value.slice(6, 10)}`
}

export const getPlainPhoneNumber = (value: string) => {
  if (value.startsWith('+1 ')) {
    value = value.slice(3);
  }
  return value.trim().replace(/[^\d]/g, '')
}

const InputPhone = React.forwardRef((
  {
    name,
    className,
    value,
    required,
    error,
    disabled,
    placeholder,
    onChange,
    showOnly,
    label,
    showClearBtn = true,
    description
  }: Props,
  ref: React.ForwardedRef<HTMLInputElement>
) => {
  value = value ?? '';
  // remove the +1 added by server
  if (value.startsWith('+1 ')) {
    value = value.slice(3);
  }
  value = value.trim().replace(/[^\d]/g, '')
  const [isFocus, setIsFocus] = useState<boolean>(false);
  const invalidReason = useMemo(() => {
    if (error) {
      return error;
    }
    if (!value) {
      return null;
    } else if (value.length !== 10) {
      return 'Invalid phone number';
    }
  }, [value, error])

  const displayValue = getPhoneDisplayValue(value);
  if (showOnly) {
    return <InputGroup>
      <div className={className}>{displayValue}</div>
    </InputGroup>
  }
  return (<>
      <div className="input-group">
        <Form.Control
          type='text'
          name={name}
          ref={ref}
          className={invalidReason ? classNames(className, 'is-invalid') : className}
          value={displayValue}
          required={required}
          placeholder={placeholder ? placeholder : '(XXX) XXX-XXXX'}
          disabled={disabled}
          onChange={(e) => {
            if (onChange && !disabled) {
              // remove any non digits
              e.target.value = e.target.value.trim().replace(/[^\d]/g, '').slice(0, 10);
              onChange(e)
            }
          }}
          onBlur={() => {
            setTimeout(() => {
              setIsFocus(false);
            }, 200);
          }}
          onFocus={() => {
            setIsFocus(true)
          }}
        />
        {
          label &&
            <Form.Label className={classNames(value ? 'has-value' : '')}>
                <span className={invalidReason ? 'text-danger' : ''}>{label}</span>
            </Form.Label>
        }
        {
          !showOnly && showClearBtn && <>
            {
              invalidReason && <div className="border border-danger rounded-circle invalid-circle">
                    <FaExclamation className={'text-danger'} size={11}/>
                </div>
            }
            {
              isFocus && value && name && showClearBtn ?
                <div className="cross-clear"
                     onClick={() => onChange && onChange({target: {value: ''}} as React.ChangeEvent<FormControlElement>)}>
                  <FaTimes size={20}/>
                </div> : <div className="cross-clear-hide"></div>
            }
            {
              invalidReason ?
                <div className="invalid-feedback">
                  {invalidReason}
                </div>
                :
                null
            }
            </>
        }

      </div>
      {description && <small>{description}</small>}
    </>
  )
})

export default InputPhone;