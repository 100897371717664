import {getAccessToken} from '@common/token';
import getUserApiPath from '../getUserApiPath';
import openUrlWithToken from "@hornet-api/openUrlWithToken";

const VIEW = '/contact/fund/currentFinancials/view';
const DOWNLOAD = '/contact/fund/currentFinancials/download';

const getFinancialsUrl = () => ({
	viewFinancialsUrl: openUrlWithToken(`${getUserApiPath()}${VIEW}`),
	downloadFinancialsUrl: `${getUserApiPath()}${DOWNLOAD}?access_token=${encodeURIComponent(getAccessToken() || '')}`,
});

export default getFinancialsUrl;
