import getAdminApiPath from "@hornet-api/getAdminApiPath";
import axios from "axios";
import {EmailLogEnum} from "@interfaces/GeneratedEnums";

export type IEmailLog = {
  id: number;
  recipients: string;
  subject: string;
  status: keyof typeof EmailLogEnum;
  lastUpdated: string;
  contactIds: string | null;
}
export const getEmailLogData = async () => {
  const url = `${getAdminApiPath()}/email-log/listData`;
  const response = await axios.get(url);
  return response.data as IEmailLog[];
}