import React, {useCallback} from 'react';
import * as Auth from '@server-api/index';
import useTokenExpirationTimer from '@state/recoil/authentication/useTokenExpirationTimer';

const TokenRefreshMonitor = () => {
	const handleTokenRefresh = useCallback(async () => {
		try {
			const results = await Auth.refreshUserToken();
			if (results && results.length) return results;
			return null;
		} catch (oe) {
			return null;
		}
	}, []);

	useTokenExpirationTimer(handleTokenRefresh);

	return null;
};

export default React.memo(TokenRefreshMonitor);
