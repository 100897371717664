import React, {useEffect} from 'react';
import {useRecoilState, useRecoilValue} from 'recoil';
import {getAccessToken} from '@common/token';
import getReporting from '@hornet-api/reporting/getReporting';
import appReadyStateSelectorAtom from '@state/recoil/appReadyStateSelectorAtom';
import isUserRoleSelectorAtom from '@state/recoil/authentication/roles/isUserRoleSelectorAtom';
import apiUrlSelectorAtom from '@state/recoil/apiUrlSelectorAtom';
import reportingAtom from '@state/recoil/reporting/reportingAtom';
import profileAtom from '@state/recoil/profileAtom';

const ReportingLoader = () => {
	const apiUrl = useRecoilValue(apiUrlSelectorAtom);
	const isReady = useRecoilValue(appReadyStateSelectorAtom);
	const isUserRole = useRecoilValue(isUserRoleSelectorAtom);
	const [reporting, setReporting] = useRecoilState(reportingAtom);
	const profile = useRecoilValue(profileAtom);

	const accessToken = getAccessToken();

	useEffect(() => {
		if (isReady && isUserRole && accessToken !== null && reporting === null) {
			getReporting()
				.then(setReporting)
				.catch(() => {
					setReporting(null);
				});
		}
	}, [isReady, isUserRole, setReporting, apiUrl, accessToken, profile]);

	return null;
};

export default React.memo(ReportingLoader);
