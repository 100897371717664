import React, {useMemo} from "react";
import {IPicture} from "@interfaces/Property";
import style from "@admin-ui/pages/UploaderPage/UploaderPage.module.scss";

type Props = {
  images: IPicture[] | null;
  primaryImageId: number | null;
}
export const ThumbnailImage = ({images, primaryImageId}: Props) => {
  const imageUrl = useMemo(() => {
    const publicImages = (images || []).filter(i => i.isPublic);
    if (!publicImages.length) return null;

    const publicImage = publicImages.find(i => i.id === primaryImageId) || publicImages[0];
    return publicImage.thumbnailUrl || publicImage.url;

  }, [primaryImageId, images]);


  return (
    <div className={`${style.imageContainer}`}>
      {
        imageUrl ?
          <div style={{backgroundImage: `url("${imageUrl}")`}}
               className={`${style.defaultImage}`}></div> :
          <div className={`${style.defaultImage}`}></div>
      }
    </div>
  );
  //return image && 'fallback-url'; // You can change 'fallback-url' to your desired default URL or handle it as needed
};