/* eslint-disable curly */
/* eslint-disable no-console */
import {useEffect, useRef} from 'react';
import {useSetRecoilState} from 'recoil';
import {setBaseUri} from '@common/baseUri';
import getConfiguration from '@server-api/getConfiguration';
import configurationAtom from '@state/recoil/configurationAtom';
import getFeatures from "@hornet-api/feature/getFeatures";
import featuresState from "@state/globalState/featuresState";
import {SystemConfigEnum} from "@interfaces/GeneratedEnums";
import timeZoneState from "@state/globalState/timeZoneState";
import getSystemConfigPublic from "@hornet-api/systemConfigs/public/getSystemConfigPublic";

const FIVE_MINUTES = 300000;
const ONE_MINUTE = 60000;

const ConfigurationMonitor = () => {
	const setConfiguration = useSetRecoilState(configurationAtom);
  const lastRefresh = useRef<number>(0);
  const lastRefreshStatus = useRef<boolean>(true);

	useEffect(() => {
		console.info('Configuration Monitoring Service started');
		const interval = setInterval(async () => {
			console.group('Configuration Monitoring Service');
			let doRequest = false;
      if (!lastRefresh.current) doRequest = true;
      if (!lastRefreshStatus.current) doRequest = true;

			if (!doRequest) {
				const lastTime = lastRefresh.current ?? Date.now();
				const timespan = Date.now() - lastTime;
				console.info('Last configuration was received', new Date(lastTime));
        console.info('Next configuration should be pulled around', lastRefresh.current && new Date(lastRefresh.current + FIVE_MINUTES));
				if (timespan >= FIVE_MINUTES) {
					doRequest = true;
				} else {
					console.info('Not enough time has passed, skipping');
				}
			}

			if (doRequest) {
				console.info('Requesting new configuration');
				try {
					const config = await getConfiguration();
					const features = await getFeatures();
					await getSystemConfigPublic(SystemConfigEnum.TIME_ZONE_KEY)
						.then(timeZoneConfig => timeZoneState.set(timeZoneConfig?.keyValue || ''))
						.catch(() => {
							// TimeZone config not found. set default blank string
							timeZoneState.set('');
						});
					lastRefreshStatus.current = true;
					featuresState.set(features);
					setConfiguration(config);
					setBaseUri(config?.api_uri_legacy ?? null);
				} catch (oe) {
					lastRefreshStatus.current = false;
				}
				lastRefresh.current = Date.now();
			}

			console.groupEnd();

		}, ONE_MINUTE);

		return () => {
			if (interval) {
				console.info('Configuration Monitoring Service stopping');
				clearInterval(interval);
			}
		};

	}, [setConfiguration, lastRefresh, lastRefreshStatus]);

	return null;
};

export default ConfigurationMonitor;
