import {getNumChatNotifications, openChat} from "@components/NotificationSidebar/utils";
import liveChatIcon from "@images/livechat.png";
import React, {useEffect, useMemo, useState} from "react";

import "./LiveChatMobile.scss";
import {chatsState} from "@components/NotificationSidebar/globalState";
import {Badge} from "react-bootstrap";
import {mobileFooterState} from "@components/BootstrapSmartTable/MobileFooter";

const LiveChatMobile = () => {
  const [className, setClassName] = useState<string>('');
  const chatObj = chatsState.useValue();
  const isFooterView = mobileFooterState.useValue();

  const numChatMessages = useMemo(() => {
    if (chatObj) {
      let count = 0;
      if (chatObj.contactChat)
        count += getNumChatNotifications(chatObj.contactChat);
      if (chatObj.contactChats) {
        chatObj.contactChats.map((chat) => {
          count += getNumChatNotifications(chat);
        });
      }
      if (chatObj.loanChats) {
        chatObj.loanChats.map((chat) => {
          count += getNumChatNotifications(chat);
        });
      }
      return count;
    } else {
      return 0;
    }
  }, [chatObj]);

  useEffect(() => {
    let timeout = null as NodeJS.Timeout | null;
    document.addEventListener('touchmove', () => {
      if (timeout) clearTimeout(timeout)

      setClassName('hidden');
      timeout = setTimeout(() => {
        setClassName('visible');
      }, 1000)
    })
  }, []);

  return (
    <span
      className={`live-chat-icon ${className} ${isFooterView ? 'footer-added' : ''}`}
      onClick={(event: any) => {
        event.preventDefault();
        openChat();
      }}
    >
      {/*Live Chat*/}
      <img style={{
        height: 35,
        width: 'auto',
        transform: 'translateY(2px)'
      }} src={liveChatIcon} alt={'Live Chat'}/>
      {
        numChatMessages > 0 &&
        (
          <Badge
            variant={'danger'}
            className={'p-0 chat-message-count'}
          >
            {numChatMessages}
          </Badge>
        )
      }
    </span>
  )
}

export default LiveChatMobile;