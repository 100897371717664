import React, {useEffect, useMemo, useRef, useState} from 'react';
import {
  chatsResultsState,
  chatsState,
  companyListState,
  loanListState,
  messageResultState,
  notificationSidebarIsOpenState,
  selectedChatState,
  selectedUserOrCompanyState,
  userListState,
} from './globalState';
import isAdminState from "@state/globalState/isAdminState";
import {Chat, ChatMessage} from '@interfaces/task';
import {FaChevronLeft} from 'react-icons/fa';
import Messages from './components/Messages';
import MessageInput from './components/MessageInput';
import ChatItem from './components/ChatItem';
import style from './NotificationMessages.module.scss';
import classNames from 'classnames';
import {
  deleteChat,
  getChatName,
  getIsAnnouncement,
  getSelectedChatItem,
  getUserCompanyLoanSelectGroups,
  refreshChatDetails,
  refreshChatObject
} from './utils';
import Empty from './components/Empty';
import useFromNull from "@common/useFromNull";
import useToNull from "@common/useToNull";
import BaseSelectBox from "@components/forms/BaseSelectBox";
import {alertApiErrors} from "@common/errors";

const NotificationMessages = () => {
  const [messageToEdit, setMessageToEdit] = useState(null as null | ChatMessage)
  const [selectedChat, setSelectedChat] = selectedChatState.use();
  const isSidebarOpen = notificationSidebarIsOpenState.useValue();
  const isAdmin = isAdminState.useValue();
  const chatObj = chatsState.useValue();
  const chatResultsObj = chatsResultsState.useValue();
  const [selectedOption, setSelectedOption] = selectedUserOrCompanyState.use();

  const userList = userListState.useValue();
  const companyList = companyListState.useValue();
  const loanList = loanListState.useValue();
  const [messages, setMessages] = messageResultState.use();
  const inputRef = useRef<HTMLTextAreaElement>(null);

  const focus = () => {
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, 300)
  };

  useEffect(() => {
    refreshChatObject(true);
  }, [selectedOption])

  useEffect(() => {
    if (isSidebarOpen) {
      refreshChatDetails(true);
    }
  }, [isSidebarOpen])

  const selectOptions = useMemo(() => {
    if (isAdmin) {
      return getUserCompanyLoanSelectGroups();
    } else {
      return [];
    }
  }, [userList, companyList, loanList, isAdmin]);

  const filteredChats = useMemo(() => {
    return chatResultsObj ?? chatObj;
  }, [chatObj, chatResultsObj]);

  const selectedChatItem: Chat | null = useMemo(() => {
    return getSelectedChatItem();
  }, [selectedChat, chatObj, chatResultsObj, isAdmin]);

  const isAnnouncement = useMemo(() => {
    return getIsAnnouncement();
  }, [selectedChatItem, chatObj, chatResultsObj])

  const isEmpty = useMemo(() => {
    if (!filteredChats) return true;
    return !filteredChats.announcementBorrower && !filteredChats.announcementInvestor &&
      !filteredChats.contactChat &&
      (!filteredChats.contactChats || filteredChats.contactChats.length === 0) &&
      (!filteredChats.loanChats || filteredChats.loanChats.length === 0);
    // &&
    // (!filteredChats.entityChats || filteredChats.entityChats.length === 0);
  }, [filteredChats]);

  useFromNull(() => {
    refreshChatDetails(true);
    focus();
  }, [selectedChatItem])

  useEffect(() => {
    focus();
  }, []);

  useToNull(() => {
    setMessages(null);
  }, [selectedChatItem])

  const getAnnouncementName = () => {
    let cleanName = '';
    if (isAnnouncement === 'borrower') cleanName = 'Borrower';
    if (isAnnouncement === 'investor') cleanName = 'Investor';
    return `Announcements (${cleanName})`
  }

  if (selectedChatItem !== null) {
    const chatName = isAnnouncement ? getAnnouncementName() : getChatName(selectedChatItem);
    return (
      <div className={isAdmin ? classNames(style.isAdmin, style.messages) : style.messages}>
        <div className={style.top}>
          <div
            className={style.left}
            onClick={() => {
              setSelectedChat(null)
            }}
          >
            <FaChevronLeft/> BACK
          </div>
          <div className={style.right}>
            <div className={style.ellipsis}>
              {chatName}
            </div>
          </div>
        </div>
        <div className={style.bottom}>
          <Messages
            chat={selectedChatItem}
            isResolved={selectedChatItem.isResolved}
            messages={messages ? messages : []}
            onSubmitted={async () => {
              await Promise.all([refreshChatDetails(true)]);
            }}
            isAnnouncement={isAnnouncement}
            messageToEdit={messageToEdit}
            onEdit={(message) => setMessageToEdit(message)}
            onDelete={(message) =>
              message &&
              deleteChat(message.id)
                .then(() => refreshChatDetails(true))
                .catch(alertApiErrors)
            }
          />
          {
            isAnnouncement && !isAdmin ?
              null
              :
              <MessageInput
                chat={selectedChatItem}
                ref={inputRef}
                messageToEdit={messageToEdit}
                onSubmitted={async () => {
                  setMessageToEdit(null);
                  refreshChatObject(false);
                  await Promise.all([refreshChatDetails(true)]);
                }}
              />
          }
        </div>
      </div>
    );
  }
  return (
    <div className={style.chatList}>
      {
        isAdmin ?
          <div className={style.adminHeader}>
            <div className={style.selectBox}>
              <BaseSelectBox
                value={selectedOption}
                options={selectOptions}
                isClearable={true}
                onChange={(e: { value: string; label: string } | null) => {
                  setSelectedOption(e);
                }}
              />
            </div>
          </div>
          :
          null
      }
      {/* Announcements */}
      {
        filteredChats?.announcementInvestor ?
          <>
            <div className={style.title}>Announcements (Investor)</div>
            <ChatItem
              title={"Announcements (Investor)"}
              chat={filteredChats.announcementInvestor}
              onClick={() => {
                if(filteredChats.announcementInvestor)
                  setSelectedChat(filteredChats.announcementInvestor.id);
              }}
            />
          </>
          :
          null
      }

      {
        filteredChats?.announcementBorrower ?
          <>
            <div className={style.title}>Announcements (Borrower)</div>
            <ChatItem
              title={"Announcements (Borrower)"}
              chat={filteredChats.announcementBorrower}
              onClick={() => {
                if(filteredChats.announcementBorrower)
                  setSelectedChat(filteredChats.announcementBorrower.id);
              }}
            />
          </>
          :
          null
      }

      {/* Contact Chat */}
      {
        filteredChats?.contactChat ?
          <>
            <div className={style.title}>User Chat</div>
            <ChatItem
              chat={filteredChats.contactChat}
              onClick={() => {
                //@ts-ignore
                setSelectedChat(filteredChats.contactChat.id);
              }}
            />
          </>
          :
          null
      }

      {/* Contact Chats */}
      {
        filteredChats?.contactChats ?
          <>
            <div className={style.title}>User Chats</div>
            {
              filteredChats.contactChats.map((chat, index) => {
                return (
                  <ChatItem
                    chat={chat}
                    key={index}
                    onClick={() => {
                      setSelectedChat(chat.id);
                    }}
                  />
                )
              })
            }

          </>
          :
          null
      }

      {/* Entity Chats */}
      {
        /*filteredChats?.entityChats ?
          <>
            <div className={style.title}>Entity Chats</div>
            {
              filteredChats.entityChats.map((chat, index) => {
                return (
                  <ChatItem
                    chat={chat}
                    key={index}
                    onClick={() => {
                      setSelectedChat(chat.id);
                    }}
                  />
                )
              })
            }

          </>
          :
          null*/
      }
      {/* Loan Chats */}
      {
        filteredChats?.loanChats ?
          <>
            <div className={style.title}>Loan Chats</div>
            {
              filteredChats.loanChats.map((chat, index) => {
                return (
                  <ChatItem
                    chat={chat}
                    key={index}
                    onClick={() => {
                      setSelectedChat(chat.id);
                    }}
                  />
                )
              })
            }

          </>
          :
          null
      }
      {isEmpty ? <Empty type='chat'/> : null}
    </div>
  );
}

export default NotificationMessages;