import React from 'react';
import {Link} from 'react-router-dom';

const NotFoundPage: React.FC = () => {
  const absoluteUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}${window.location.search}${window.location.hash}`;

  return (
    <div style={{textAlign: 'center', marginTop: '50px'}}>
      <h1>404 Not Found</h1>
      <p><i className={'d-block'}>`{absoluteUrl}`</i> doesn't exist.</p>
      <Link to={'/home'}></Link>
    </div>
  );
};

export default NotFoundPage;
