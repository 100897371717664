import NavigationButton from "@admin-ui/pages/UploaderPage/components/NavigationButton";
import style from "@admin-ui/pages/UploaderPage/UploaderPage.module.scss";
import {Card, Col, Row} from "react-bootstrap";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {IPicture, PropertyRaw} from "@interfaces/Property";
import ControlsButton from "@admin-ui/pages/UploaderPage/Property/ControlsButton";
import moment from "moment/moment";
import {DateFormats} from "@interfaces/DateFormats";
import PropertyAddress from "@admin-ui/pages/UploaderPage/components/PropertyAddress";
import UploadImageButton from "@admin-ui/pages/UploaderPage/components/UploadImageButton";
import {
  addPropertyImage,
  deletePropertyImage,
  downloadPropertyImage,
  makePropertyImagePrimary,
  makePropertyImagePrivate,
  makePropertyImagePublic
} from "@hornet-api/properties/images";
import {alertApiErrors} from "@common/errors";
import {loadingRelease, loadingTrigger} from "@components/LoadingOverlay";
import fileDownloader from "@common/utils/fileDownloader";
import {sortByKey} from "@common/basic";
import ImageGrid from "@admin-ui/pages/UploaderPage/Property/ImageGrid";
import classNames from "classnames";
import DEFAULT_PLACEHOLDER from "@admin-ui/pages/UploaderPage/components/placeholder";
import {createFileName} from "@common/utils/fileUtil";


type Props = {
  property: PropertyRaw
  setProperty: (property: PropertyRaw) => void
  isNavigation?: boolean
}
export const PropertyView = ({property, setProperty, isNavigation = true}: Props) => {
  const {id, images, primaryImageId} = property
  const [focussedImage, setFocussedImage] = useState<IPicture>();

  const totalImage = useMemo(() => (images || []).length, [property])


  const tags = useMemo(() => {
    if (!focussedImage) return null;
    if (focussedImage.id === primaryImageId) {
      return (
        <>
          <span className="badge badge-pill badge-primary mr-1">Primary</span>
          <span className="badge badge-pill badge-success">Public</span>
        </>
      );
    }
    if (focussedImage.isPublic) {
      return (
        <span className="badge badge-pill badge-success">Public</span>
      );
    }
    return (
      <span className="badge badge-pill badge-warning">Private</span>
    );
  }, [focussedImage, property, primaryImageId]);

  const enableMakePrimary = useMemo(() => (
    focussedImage && focussedImage.isPublic && primaryImageId !== focussedImage.id
  ), [images, focussedImage, primaryImageId]);


  const handleMakePrimary = useCallback(() => {
    if (!focussedImage) return;
    makePropertyImagePrimary(id, focussedImage.id)
      .then((data) => {
        const newState: PropertyRaw = {
          ...property,
          primaryImageId: data.id
        };
        setProperty(newState);
      })
      .catch(alertApiErrors);
  }, [id, focussedImage, property, primaryImageId]);

  const handlePrivacyToggle = useCallback(() => {
    if (!focussedImage) return;
    const t = loadingTrigger();
    (
      focussedImage.isPublic ?
        makePropertyImagePrivate(id, focussedImage.id)
        : makePropertyImagePublic(id, focussedImage.id)
    ).then((data) => {
      const newState: PropertyRaw = {
        ...property,
        images: property.images ? property.images.map(i => ({
          ...i,
          isPublic: i.id === data.id ? data.isPublic : i.isPublic,
        })) : []
      };
      setFocussedImage({...focussedImage, isPublic: data.isPublic});
      setProperty(newState);
    })
      .catch(alertApiErrors)
      .finally(() => loadingRelease(t));
  }, [id, focussedImage, property]);


  const handleOnDownload = useCallback(() => {
    if (!focussedImage) return;
    const t = loadingTrigger();
    fileDownloader(
      downloadPropertyImage(id, focussedImage.id),
      createFileName(focussedImage.title || 'image', focussedImage.fileType)
    )
      .finally(() => loadingRelease(t));
  }, [id, focussedImage, property]);

  const handleDelete = useCallback(() => {
    if (!focussedImage) return;
    const t = loadingTrigger();
    deletePropertyImage(id, focussedImage.id).then(() => {
      const newState = {
        ...property
      };
      if (newState?.images) {
        const imageIndex = newState.images.findIndex(i => i.id === focussedImage.id);
        newState.images.splice(imageIndex, 1);
        setProperty(newState)
        setFocussedImage(undefined);
      }
    }).catch(alertApiErrors)
      .finally(() => loadingRelease(t));

  }, [id, focussedImage, property]);


  const handleImageUpload = useCallback((files) => {
    if (!id) return;
    const t = loadingTrigger();
    addPropertyImage(id, files)
      .then((data) => {
        setProperty({
          ...property,
          images: [
            ...(property.images || []),
            ...data,
          ],
        });
      })
      .catch(alertApiErrors)
      .finally(() => loadingRelease(t));

  }, [id, property]);

  useEffect(() => {
    if (!focussedImage && property.images) {
      setFocussedImage(property.images.find(i => i.id === primaryImageId) || sortByKey(property.images, 'id')[0]);
    }
  }, [property, focussedImage]);


  const displayDate = useMemo(() => {
    const image = focussedImage;
    if (!image) {
      return '';
    }
    return `${
      image.dateTaken ? 'Taken' : 'Uploaded'
    } Date - ${moment(image.dateTaken || image.created).format(DateFormats.DATE)}`
  }, [focussedImage])

  const imagePaths = useMemo(() => {
    const backgroundImages = [`url(${DEFAULT_PLACEHOLDER}`];

    if (focussedImage?.url) {
      backgroundImages.push(`url(${focussedImage.url})`);
    }

    return backgroundImages.reverse().join(', ');
  }, [focussedImage]);


  return (
    <Card>
      <Card.Body>
        <Row>
          <Col xs={12} sm={6}>
            <div style={{display: 'flex', paddingBottom: '10px'}}>

              {isNavigation && <NavigationButton/>}
              <ControlsButton
                disabled={!focussedImage}
                onDeleteClick={handleDelete}
                onMakePrimaryClick={handleMakePrimary}
                onPrivacyToggle={handlePrivacyToggle}
                onSaveClick={handleOnDownload}
                allowMakePrimary={enableMakePrimary}
                canDelete={!!focussedImage}
                primary={primaryImageId === focussedImage?.id}
                isPublic={focussedImage?.isPublic}

              />
              <div className={style.displayFlexCenter}>
                {totalImage} image{totalImage > 2 ? 's' : ''}
              </div>
              <UploadImageButton onChange={handleImageUpload}/>
            </div>
            
            <div className={style.mainImage} style={{backgroundImage: imagePaths}}></div>
            <div className={classNames(style.displayFlexCenter, style.tags)}>{tags}</div>
            <div className={style.displayFlexCenter}> {displayDate} </div>
            <PropertyAddress
              street1={property.address1 || ''}
              street2={property.address2 || ''}
              city={property.city || ''}
              state={property.state || ''}
              zip={property.zip || ''}
              style={{textAlign: 'center'}}
            />
          </Col>
          <Col xs={12} sm={6}>
            {
              images && focussedImage &&
                <ImageGrid images={images}
                           setFocussedImage={setFocussedImage}
                           focussedImage={focussedImage}
                           primaryImageId={primaryImageId}
                />
            }
          </Col>
        </Row>
      </Card.Body></Card>

  );
}