import {selector} from 'recoil';
import userStateReadyAtom from './userStateReadyAtom';
import configurationAtom from './configurationAtom';

const appReadyStateSelectorAtom = selector<boolean>({
	key: 'appReadyStateSelectorAtom',
	get: ({get}) => {
		const userStateReady = get(userStateReadyAtom);
		const configuration = get(configurationAtom);
		return userStateReady && !!configuration && true;
	},
});

export default appReadyStateSelectorAtom;
