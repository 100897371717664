import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import {createGlobalState} from 'react-global-hooks';
import style from './AmIAccredited.module.scss';
import classnames from 'classnames';

export const getAmIAccreditedModalLink = (isUnderLabel = false) => {
  return (
    <div
      className={isUnderLabel ? classnames(style.link, style.underLabel) : style.link}
      onClick={showAmIAccreditedModal}
    >
      Am I an Accredited Investor?
    </div>
  );
};

export const showAmIAccreditedModalState = createGlobalState(false);

export const showAmIAccreditedModal = () => {
  showAmIAccreditedModalState.set(true);
};

const AmIAccreditedModal = () => {
  const [show, setShow] = showAmIAccreditedModalState.use();

  const handleClose = () => setShow(false);

  return (
    <>
      <Modal size={'xl'} show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Am I an Accredited Investor?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <p>
              To qualify as an Accredited Investor, an investor must meet any of the following categories
            </p>

            <p>
              <strong>INDIVIDUALS</strong> can qualify by falling into any of the following categories
            </p>
            <ul>
              <li>
                <div className={style.title}>INDIVIDUAL FINANCIAL</div>
                <div className={style.smallTitle}>This is the most common</div>
                <ul>
                  <li>
                    A natural person whose individual net worth, or joint net worth with that person's
                    spouse, at the time of his purchase exceeds $1,000,000 (excluding the value of such
                    person's primary residence); or
                  </li>
                  <li>
                    A natural person who had an individual income in excess of $200,000 in each of the two
                    most recent years or joint income with that person's spouse in excess of $300,000 in
                    each of those years and has a reasonable expectation of reaching the same income level
                    in the current year.
                  </li>
                </ul>
              </li>
              <li>
                <div className={style.title}>INDIVIDUAL PROFESSIONAL</div>
                <ul>
                  <li>
                    A director, executive officer, or general partner of the issuer of the securities
                    being offered or sold, or any director, executive officer, or general partner of a
                    general partner of that issuer; or
                  </li>
                  <li>
                    A natural person who is a “knowledgeable employee,” as defined in rule 3c-5(a)(4)
                    under the Investment Company Act of 1940, of the issuer of the securities being
                    offered or sold where the issuer would be an investment company, as defined in section
                    3 of such act, but for the exclusion provided by either section 3(c)(1) or section
                    3(c)(7) of such act; or
                  </li>
                  <li>
                    Any natural person holding in good standing one or more professional certifications or
                    designations or credentials from an accredited educational institution that the
                    Commission has designated as qualifying an individual for accredited investor status.
                  </li>
                  <li>
                    A “family client,” as defined in rule 202(a)(11)(G)-1 under the Investment Advisers
                    Act of 1940, of a family office meeting the requirements in 230.501(a)(12) and whose
                    prospective investment in the issuer is directed by such family office pursuant to
                    paragraph 230.501(a)(12)(iii).
                  </li>
                </ul>
              </li>
            </ul>

            <p>
              <strong>ENTITIES</strong> can qualify by falling into any of the following categories
            </p>
            <ul>
              <li>
                <div className={style.title}>ENTITY OWNERS ARE ALL ACCREDITED</div>
                <div className={style.smallTitle}>This is the most common</div>
                <ul>
                  <li>An entity in which all of the equity owners are accredited investors.</li>
                </ul>
              </li>
              <li>
                <div className={style.title}>ENTITY FINANCIAL ENTITY</div>
                <div className={style.smallTitle}>Self-Directed IRAs and 401k</div>
                <ul>
                  <li>
                    An employee benefit plan within the meaning of the Employee Retirement Income Security
                    Act of 1974, which is a self-directed plan such as an IRA, with investment
                    decisions made solely by persons that are accredited investors.
                  </li>
                  <li>
                    An employee benefit plan within the meaning of the Employee Retirement Income Security
                    Act of 1974 if the investment decision is made by a plan fiduciary, as defined in
                    section 3(21) of such Act, which is either a bank, savings and loan association,
                    insurance company, or registered adviser, or if the employee benefit plan has total
                    assets in excess of $5,000,000; or
                  </li>
                  <li>
                    A bank as defined in section 3(a)(2) of the Act, or any savings and loan association
                    or other institution as defined in section 3(a)(5)(A) of the Act whether acting in its
                    individual or fiduciary capacity; An insurance company as defined in section 2(13) of
                    the Act; An investment company registered under the Investment Company Act of 1940 or
                    a business development company as defined in section 2(a)(48) of that Act; A Small
                    Business Investment Company licensed by the U.S. Small Business Administration under
                    section 301(c) or (d) of the Small Business Investment Act of 1958; a Rural Business
                    Investment Company as defined in section 384A of the Consolidated Farm and Rural
                    Development Act; A plan established and maintained by a state, its political
                    subdivisions, or any agency or instrumentality of a state or its political
                    subdivisions, for the benefit of its employees, if such plan has total assets in
                    excess of $5,000,000; or
                  </li>
                  <li>
                    A private business development company as defined in section 202(a)(22) of the
                    Investment Advisers Act of 1940;
                  </li>
                </ul>
              </li>

              <li>
                <div className={style.title}>ENTITY ASSETS</div>
                <ul>
                  <li>
                    An organization as described in section 501(c)(3) of the Internal Revenue Code,
                    corporation, Massachusetts or similar business trust, or partnership, not formed for
                    the specific purpose of acquiring the securities offered, with total assets in excess
                    of $5,000,000; or
                  </li>
                  <li>
                    A trust, with total assets in excess of $5,000,000, not formed for the specific
                    purpose of acquiring the securities offered, whose purchase is directed by a
                    sophisticated person as described in section 230.506(b)(2)(ii) who has such knowledge
                    and experience in financial and business matters that he or she is capable of
                    evaluating the merits and risks of the prospective investment in the Company; or
                  </li>
                  <li>
                    An entity, of a type not listed in 230.501(a)(1), (2), (3), (7), or (8), not formed
                    for the specific purpose of acquiring the securities offered, owning investments in
                    excess of $5,000,000; or
                  </li>
                  <li>
                    A “family office,” as defined in rule 202(a)(11)(G)-1 under the Investment Advisers
                    Act of 1940 (i) with assets under management in excess of $5,000,000, (ii) that is not
                    formed for the specific purpose of acquiring the securities offered, and (iii) whose
                    prospective investment is directed by a person who has such knowledge and experience
                    in financial and business matters that such family office is capable of evaluating the
                    merits and risks of the prospective investment;
                  </li>
                </ul>
              </li>

              <li>
                <div className={style.title}>ENTITY INVESTMENT ADVISER</div>
                <ul>
                  <li>
                    A broker or dealer registered pursuant to section 15 of the Securities Exchange Act
                    1934; An investment adviser registered pursuant to section 203 of the Investment
                    Advisers Act of 1940 or registered pursuant to the laws of a state; An investment
                    adviser relying on the exemption from registering with the Commission under section
                    203(l) or (m) of the Investment Advisers Act of 1940;
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AmIAccreditedModal;
