import React, {useCallback, useRef} from 'react';
import Pressable from "@admin/components/buttons/Pressable";
import FontAwesomeIcon from "@admin/components/icons/FontAwesomeIcon";

interface IUploadImageButtonProps {
	onChange: (files: FileList | null) => void;
}

const UploadImageButton = (
	{
	onChange
	}: IUploadImageButtonProps
) => {
	const fileInputRef = useRef<HTMLInputElement>(null);

	const handleOnClick = useCallback((event) => {
		if (event && event.preventDefault) event.preventDefault();
		const fileInput = fileInputRef.current;
		if (fileInput) fileInput.click();
	}, [fileInputRef]);

	const handleOnChange = useCallback(() => {
		if (!fileInputRef.current) {
			return;
		}
		const fileInput = fileInputRef.current;
		const files = fileInput.files;

		if (typeof onChange === 'function') onChange(files);
		fileInputRef.current.value = '';
	}, [onChange]);

	return(
		<div>
			<Pressable className="btn btn-primary" onClick={handleOnClick}>
				<FontAwesomeIcon icon="camera" /> Upload Image
			</Pressable>
			<input
				type="file"
				ref={fileInputRef}
				onChange={handleOnChange}
				accept="image/*"
				className={'d-none'}
				multiple
			/>
		</div>
	);
};

export default UploadImageButton;
