import React, {useMemo} from "react";
import {userTypingChatsGlobalState} from "@components/NotificationSidebar/globalState";
import {prettyList} from "@common/basic";
import "./UserTypingLoader.scss";

const UsersTypingList = ({chatId}: { chatId: number }) => {
  const usersTypingStateList = userTypingChatsGlobalState.useValue()

  const usersTypingNow = useMemo(() => {
    return usersTypingStateList.filter(({id}) => id === chatId)
  }, [usersTypingStateList, chatId]);

  return (
    <>
      {usersTypingNow?.length > 0 && (
        <div className={'text-left text-info small'}>
          {prettyList(usersTypingNow.map(({username}) => username))} {usersTypingNow.length > 1 ? 'are' : 'is'} typing<span
          className={'userTypingLoader'}>...</span>
        </div>
      )}
    </>
  )
}
export default UsersTypingList;