import axios from 'axios';
import getAdminApiPath from '../getAdminApiPath';
import {EmailTriggerEnum} from "@interfaces/GeneratedEnums";

interface ICreatedBy {
  id: number
}

export interface IDefNotificationQueue {
  id: number
  triggerAction: string
  runAt: string
  timeLeft: number
  emailTrigger: keyof typeof EmailTriggerEnum
}

export const listForMe = async () => {
  const url = `${getAdminApiPath()}/defNotificationQueue/listForMe?t=${Date.now()}`;
  const response = await axios.get(url);
  return response.data as IDefNotificationQueue[];
};
