import React, {useEffect} from 'react';
import {useRecoilState, useRecoilValue} from 'recoil';
import {getAccessToken} from '@common/token';
import getUserProfile from '@hornet-api/profile/getUserProfile';
import appReadyStateSelectorAtom from '@state/recoil/appReadyStateSelectorAtom';
import apiUrlSelectorAtom from '@state/recoil/apiUrlSelectorAtom';
import profileAtom from '@state/recoil/profileAtom';
import profileState from '@state/globalState/profileState';
import activeRoleState from "@state/globalState/activeRoleState";
import {setDefaultRole} from "@common/isRole";
import userRoleGroupsState from "@state/globalState/userRoleGroupsState";

const ProfileStateLoader = () => {
	const apiUrl = useRecoilValue(apiUrlSelectorAtom);
	const isReady = useRecoilValue(appReadyStateSelectorAtom);
	const [profile, setProfile] = useRecoilState(profileAtom);
	const activeRole = activeRoleState.useValue();
	const userRoleGroups = userRoleGroupsState.useValue();

	const accessToken = getAccessToken();

	// update global hook state profile when this is updated
	useEffect(() => {
		profileState.set(profile);
		if (activeRole === null && profile && userRoleGroups) {
			setDefaultRole(profile);
		}
	}, [profile, activeRole, userRoleGroups]);

	useEffect(() => {
		if (isReady && accessToken !== null) {
			getUserProfile()
				.then(setProfile)
				.catch((e) => {
					console.log('error getting profile', e)
					setProfile(null);
				});
		}
	}, [isReady, accessToken, setProfile, apiUrl]);

	return null;
};

export default React.memo(ProfileStateLoader);
