// does not exist in generated
import {InvestmentAdditionalTermsEnum, RecurringPaymentFrequencyEnum} from "@interfaces/GeneratedEnums";
import {omit} from "lodash";

export enum FormOfIndividualOwnership {
	INDIVIDUAL_OWNERSHIP = 'Individual Ownership',
	COMMUNITY_PROPERTY = 'Community Property',
	JOINT_TENANTS = 'Joint Tenants with Right of Survivorship',
	TENANTS_IN_COMMON = 'Tenants in Common',
}
export enum FormOfEntityOwnership {
	GENERAL_PARTNERSHIP = 'General Partnership',
	LIMITED_PARTNERSHIP = 'Limited Partnership',
	LLC = 'LLC',
	CORPORATION = 'Corporation',
	TRUST = 'Trust',
	EMPLOYEE_BENEFIT_PLAN = 'Employee Benefit Plan'
}

export enum ConstructionStatusEnumColor {
	NONE = 'success',
	PROGRESSING_NORMALLY = 'success',
	MINOR_DELAYS = 'warning',
	MAJOR_DELAYS = 'danger',
	COMPLETE = 'success',
}

export enum PaymentStatusEnumColor {
	PERFORMING = 'success',
	NOT_PERFORMING = 'warning'
}

export enum InsuranceStatusEnumColor {
	UNMANAGED = 'success',
	COVERED = 'success',
	EXPIRING_UNDER_30 = 'warning',
	EXPIRED = 'danger',
}

export enum OverallStatusEnumColor {
	ON_TRACK = 'success',
	WATCH_LIST = 'warning',
	PENDING_FORECLOSURE = 'danger',
	ACTIVE_BANKRUPTCY = 'danger'
}

export enum PublicPrivateEnum {
	true = 'Private',
	false = 'Public'
}

export enum YesNoEnum {
	true = 'Yes',
	false = 'No'
}

export enum YesNoTextEnum {
	'Yes' = 'Yes',
	'No' = 'No',
}
export enum YesNoNAEnum {
	'Yes' = 'Yes',
	'No' = 'No',
	'N/A' = 'N/A'
}

export enum AssociateLoanContactEnum {
	'Borrower' = 'Borrower',
	'Broker' = 'Broker',
	'Escrow Officer' = 'Escrow Officer',
	'Guarantor' = 'Guarantor',
	'Lender' = 'Lender',
	'Originator' = 'Originator',
  'Title insurer' = 'Title insurer',
	'Title office' = 'Title office'
}

export enum AssociateLoanEntityEnum {
	'Borrower' = 'Borrower',
	'Broker' = 'Broker',
	'Lender' = 'Lender',
	'Originator' = 'Originator',
	'Title insurer' = 'Title insurer',
	'Title office' = 'Title office'
}

export const recurringPaymentFrequencyList: Array<keyof typeof RecurringPaymentFrequencyEnum> = [
	'WEEKLY', 'BI_WEEKLY', 'MONTHLY'
]

/**
 * Creates a modified version of `InvestmentAdditionalTermsEnum` without the
 * 'FIVE_OH_SIX_INVESTMENT_TERMS' key.
 */
export const InvestmentAdditionalTermsEnumWithout506 = omit(InvestmentAdditionalTermsEnum, 'FIVE_OH_SIX_INVESTMENT_TERMS');