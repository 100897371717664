import React, {useEffect, useMemo, useState} from 'react';
import style from './style.module.scss';
import profileState from '@state/globalState/profileState';
import {FaFileSignature} from 'react-icons/fa';
import {useSetRecoilState} from 'recoil';
import profileAtom from '@state/recoil/profileAtom';
import getUserProfile from '@hornet-api/profile/getUserProfile';
import {loadingRelease, loadingTrigger} from '@components/LoadingOverlay';
import SignNdaModal from '@components/modals/SignNdaModal';

type Props = {
  children: React.ReactNode;
};
const NdaRoadblock = (props: Props) => {
  const [isReady, setIsReady] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const profile = profileState.useValue();
  const setProfile = useSetRecoilState(profileAtom);
  const ndaRoadblockMessage = useMemo(() => {
    if (profile?.ndaStatus === 'None' || profile?.ndaStatus === 'Pending') return 'You must sign an NDA before viewing this content.';
    // if (profile?.ndaStatus === 'Pending') return 'Your NDA is pending approval.';
    return null;
  }, [profile]);

  const updateProfile = () => {
    const t = loadingTrigger();
    getUserProfile()
      .then((profile) => {
        setProfile(profile);
        setIsReady(true);
        loadingRelease(t);
      })
      .catch((e) => {
        console.error(e);
        setIsReady(true);
        loadingRelease(t);
      });
  };

  useEffect(() => {
    // update profile if we are currently roadblocked to see if it has changed
    if (ndaRoadblockMessage) {
      updateProfile();
    }
  }, [ndaRoadblockMessage]);

  if (!isReady && !!ndaRoadblockMessage) return <></>;

  return (
    <>
      {ndaRoadblockMessage ? (
        <>
          <div className={style.container}>
            <div className={style.icon}>
              <FaFileSignature />
            </div>
            <div>{ndaRoadblockMessage}</div>
            <div>
              {profile?.ndaStatus === 'None' || profile?.ndaStatus === 'Pending' ? (
                <a
                  href={'#'}
                  onClick={(event) => {
                    event.preventDefault();
                    setShowModal(true);
                  }}
                >
                  Sign NDA Online
                </a>
              ) : null}
            </div>
          </div>
          <SignNdaModal
            show={showModal}
            onComplete={() => {
              updateProfile();
            }}
            onClose={() => {
              setShowModal(false);
            }}
          />
        </>
      ) : (
        <>{props.children}</>
      )}
    </>
  );
};

export default NdaRoadblock;
