import axios from 'axios';
import getAdminApiPath from "@hornet-api/getAdminApiPath";
import {IPicture} from "@interfaces/Property";

const allowedImageMimeTypes = [
  'image/jpeg',
  'image/png',
  'image/svg+xml',
  "image/bmp",
  "image/webp",
];

export const KILOBYTE = 1024;
export const MEGABYTE = KILOBYTE * 1024;
export const MAX_MEGS = 10;
export const MAX_IMAGE_SIZE = MAX_MEGS * MEGABYTE;


export const addPropertyImage = async (propertyId: number,
                                       images: FileList) => {
  const url = `${getAdminApiPath()}/property/${propertyId}/picture`;
  const formData = new FormData();
  for (let i = 0; i < images.length; i++) {
    const image = images.item(i);
    if (!image) continue;
    if (!allowedImageMimeTypes.includes(image.type)) throw new Error(`The image type "${image.type} is not supported by the web`);
    if (Number((image.size / MEGABYTE).toFixed(4)) > MAX_IMAGE_SIZE) throw new Error(`The image is greater than ${MAX_MEGS}MB, please shrink the image`);
    formData.append(`images[${i}].image`, image);
    formData.append(`images[${i}].modifiedDate`, `${image.lastModified || ''}`);
  }
  const response = await axios.post(url, formData);
  return response.data as IPicture[];
};

export const deletePropertyImage = async (propertyId: number, imageId: number) => {
  const url = `${getAdminApiPath()}/property/${propertyId}/picture/${imageId}`;
  await axios.delete(url);
  return;
};

export const makePropertyImagePrimary = async (propertyId: number, imageId: number) => {
  const url = `${getAdminApiPath()}/property/${propertyId}/picture/${imageId}/primary`;
  const response = await axios.patch(url, {});
  return response.data as IPicture;

}

export const makePropertyImagePublic = async (propertyId: number, imageId: number) => {
  const url = `${getAdminApiPath()}/property/${propertyId}/picture/${imageId}`;
  const response = await axios.patch(url, {isPublic: true});
  return response.data as IPicture;
}

export const makePropertyImagePrivate = async (propertyId: number, imageId: number) => {
  const url = `${getAdminApiPath()}/property/${propertyId}/picture/${imageId}`;
  const response = await axios.patch(url, {isPublic: false});
  return response.data as IPicture;
}

export const downloadPropertyImage = (propertyId: number, imageId: number) => {
  return `${getAdminApiPath()}/property/${propertyId}/picture/${imageId}/download`;
}