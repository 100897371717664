import { getBaseUri } from '@common/baseUri';

const API_PATH = 'api/v1';

const getApiPath = () => {
	const baseUri = getBaseUri();
	let split = '/';
	if (baseUri?.substr(baseUri.length - 1, 1) === '/') split = '';
	return `${baseUri}${split}${API_PATH}`;
};

export default getApiPath;
