import React, {useEffect} from 'react';
import FontAwesomeIcon from './lib/FontAwesomeIcon';
import Store from './globalState';
import {Column} from './index';
import {createGlobalState} from "react-global-hooks";

type Props<D> = {
  store: Store<D>,
  columns: Column<D>[],
};

export const mobileFooterState = createGlobalState(false)
function MobileFooter<D>(
  {
    store,
    columns,
  }: Props<D>
) {
  const {mobileViewState, sortKeyState, sortAscendingState} = store;
  const [mobileView, setMobileView] = mobileViewState.use();
  const [sortKey, setSortKey] = sortKeyState.use();
  const [sortAscending, setSortAscending] = sortAscendingState.use();

  useEffect(() => {
    mobileFooterState.set(true)
    return () => {
      mobileFooterState.set(false)
    }
  }, []);

  return (
    <div className='bst-mobile-footer'>
      <div className='bst-footer-sort'>
        <div
          style={{
            color: `rgba(255, 255, 255, 0.5)`
          }}
        >
          SORT
        </div>
        <div
          style={{
            textAlign: 'center',
            color: '#fff',
            flexGrow: 1
          }}
        >
          <div className="select-group">
            <select
              value={sortKey}
              onChange={(e) => {
                setSortKey(e.target.value);
              }}
            >
              {
                columns.map((col, index) => {
                  return (
                    <option key={index} value={col.field}>{col.title}</option>
                  )
                })
              }
            </select>
          </div>
        </div>
        <div
          style={{
            fontSize: 24,
            color: '#fff',
          }}
          onClick={() => {
            const newAscending = !sortAscending;
            setSortAscending(newAscending);
          }}
        >
          <span style={{ opacity: sortAscending ? 1 : 0.5 }}>
            <FontAwesomeIcon icon='caret-up' />
          </span>
          <span style={{ opacity: sortAscending ? 0.5 : 1 }}>
            <FontAwesomeIcon icon='caret-down' />
          </span>
        </div>
      </div>
      <div
        className={`bst-mobile-item${mobileView === 'table' ? ' active' : ''}`}
        onClick={() => {
          setMobileView('table');
        }}
      >
        <div className='bmi-icon'>
          <FontAwesomeIcon icon={'table'} />
        </div>
        <div className='bmi-text'>
          Table
        </div>
      </div>

      <div
        className={`bst-mobile-item${mobileView === 'visibility' ? ' active' : ''}`}
        onClick={() => {
          setMobileView('visibility');
        }}
      >
        <div className='bmi-icon'>
          <FontAwesomeIcon icon={'eye-slash'} />
        </div>
        <div className='bmi-text'>
          Visibility
        </div>
      </div>

      <div
        className={`bst-mobile-item${mobileView === 'filter' ? ' active' : ''}`}
        onClick={() => {
          setMobileView('filter');
        }}
      >
        <div className='bmi-icon'>
          <FontAwesomeIcon icon={'filter'} />
        </div>
        <div className='bmi-text'>
          Filter
        </div>
      </div>
    </div>
  );
}

export default MobileFooter;