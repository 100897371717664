import {createGlobalState} from 'react-global-hooks';
import {ChatObject, ITask, ITaskMeta, Message, Task} from '@interfaces/task';
import Transfer from '@interfaces/Transfer';
import {ContactListItem} from '@hornet-api/task/getContactList';
import {CompanyListItem} from '@hornet-api/task/getCompanyList';
import {LoanListItem} from '@hornet-api/task/getLoanList';
import {IBudgetNotification} from "@interfaces/loan/user/loanBudget";
import {TodoState} from "@hornet-api/task/getTodoList";
import {Report} from "@interfaces/Report";
import {SelectOptionProps} from "@components/forms/react-hook-form-bootstrap/interfaces";
import {PropertyOptions} from "@hornet-api/properties/getPropertyListOptions";
import {AnnouncementWithStatus} from "@interfaces/AnnouncementWithStatus";
import {UserTypingChatWithTimeout} from "@components/NotificationSidebar/types";

export const isLoadingState = createGlobalState(0);
export const isRefreshingTaskDetailsState = createGlobalState(false);

export const notificationSidebarIsOpenState = createGlobalState(false);
export const selectedTopMenuItemState = createGlobalState('tasks' as 'messages' | 'tasks' | 'transfers');
export const isAddingTaskFromSidebarState = createGlobalState(false);
export const tasksState = createGlobalState(null as ITask[] | null);
export const tasksMetaState = createGlobalState({
  total: 0,
  totalUnresolvedCount: 0,
  page: -1
} as ITaskMeta);
export const chatsState = createGlobalState(null as ChatObject | null);
export const chatsResultsState = createGlobalState(null as ChatObject | null);
export const getChatsStateCurrent = () => {
  return chatsResultsState.get() ?? chatsState.get()
};
export const selectedTaskState = createGlobalState(null as number | null);
export const selectedTaskDetailState = createGlobalState(null as Task | null); // holds task info for selected task

export const selectedTaskIdAndActionTimeStampState = createGlobalState<{ id: number | null, timeStamp: number } | undefined>(undefined);

export const selectedChatState = createGlobalState(null as number | null);
export const messageResultState = createGlobalState(null as null | Message[]);

export const userListState = createGlobalState(null as ContactListItem[] | null);
export const companyListState = createGlobalState(null as CompanyListItem[] | null);
export const loanListState = createGlobalState(null as LoanListItem[] | null);
export const fundListState = createGlobalState(null as SelectOptionProps[] | null);
export const propertyListState = createGlobalState(null as PropertyOptions[] | null);
export const selectedUserOrCompanyState = createGlobalState(null as null | { value: string, label: string });

export const transfersState = createGlobalState(null as Transfer[] | null);
export const refreshQueueListState = createGlobalState<number>(0);

export const refreshSage2FAState = createGlobalState<number>(0);

export const submittedBudgetCountState = createGlobalState<number>(0);
export const createdDrawRequestCountState = createGlobalState<number>(0);
export const userBudgetNotificationState = createGlobalState<IBudgetNotification[]>([]);
export const isCurrentFinancialAvailableState = createGlobalState<boolean>(false);
export const latestDeletedLoanBudgetByAdminState = createGlobalState<string>('');

export const reportsState = createGlobalState(null as Report[] | null); // Initialize as an empty array

// States related to Announcements - Start
export const isLoadingAnnouncementsState = createGlobalState<boolean>(false);
export const announcementsState = createGlobalState<AnnouncementWithStatus[]>([]);
export const refreshAnnouncementsState = createGlobalState(0 as number);
export const fetchOnlyUnreadState = createGlobalState<boolean>(true);
export const userTypingChatsGlobalState = createGlobalState<UserTypingChatWithTimeout[]>([]);

// Announcements - End

export const todoListUpdated = createGlobalState({
  isPPMComplete: false,
  hasLoanInvestments: false,
  joinTheFundComplete: false,
} as TodoState);
