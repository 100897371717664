import React, {useMemo} from 'react';
import profileState from '@state/globalState/profileState';
import FontAwesomeIcon from "@components/Icons/FontAwesomeIcon/index";
import {Link} from "react-router-dom";

type Props = {
  children: React.ReactNode;
};

const AccreditedInvestorRoadBlock = ({children}: Props) => {
  const profile = profileState.useValue();

  const isAccredited = useMemo(() => {
    const status = profile?.investorProfile?.accreditedInvestorStatusOfIndividual ?? '';
    return ["ACCREDITED", "ACCREDITED_PROFESSIONAL"].includes(status);
  }, [profile]);

  return (
    <>
      {!isAccredited ? (
        <div className="alert alert-warning text-center" role="alert">
          <h4 className="alert-heading">
            <FontAwesomeIcon icon="exclamation-triangle"/> Your profile doesn't have access.
          </h4>
          <p>This feature is only available to Accredited Investors.</p>
          <hr/>
          <p>Please update your role by clicking the link below.</p>
          <Link to={`/userContact/profile`} className={'btn btn-primary'}>Profile</Link>
        </div>
      ) : (
        <>{children}</>
      )}
    </>
  );
};

export default AccreditedInvestorRoadBlock;
