import axios from 'axios';
import getUserApiPath from '../getUserApiPath';
import Contact from "@interfaces/Contact";

const getUserProfile = async () => {
	const profile_url = `${getUserApiPath()}/contact`;
	const response = await axios.get<Contact>(profile_url);
	return response.data;
};

export default getUserProfile;
