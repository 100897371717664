import {SmartAddressProps} from "@components/forms/react-hook-form-bootstrap/interfaces";
import configurationState from "@state/globalState/configurationState";
import {usePlacesWidget} from "react-google-autocomplete";
import React, {useEffect, useMemo} from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {statesTitleCase as statesObject} from "@common/states";
import {Hidden, Select, TextBox} from "@components/forms/react-hook-form-bootstrap/react-hook-form-bootstrap";
import {AddressTypeEnum} from "@interfaces/GeneratedEnums";
import {zipValidator} from "@common/utils/validators/zipValidator";

export const SmartAddress = (
  {
    name,
    rules,
    showAddressType = false,
    label,
    control,
    defaultValue,
    getDefaultFunction,
    disabled = false,
    googleSearch = false,
    setValue,
    trigger
  }: SmartAddressProps
) => {
  const configuration = configurationState.useValue();
  const {ref} = usePlacesWidget({
    apiKey: configuration?.google_maps_key,
    onPlaceSelected: (place) => {
      if (!setValue) return;
      const streetNumber = place.address_components?.find((x) =>
        x.types.includes('street_number')
      )?.long_name;
      const streetName = place.address_components?.find((x) =>
        x.types.includes('route')
      )?.long_name;
      const address1 = `${streetNumber ?? ''} ${streetName ?? ''}`;
      setValue(`${name}.address1`, address1);

      const city = place.address_components?.find((x) => x.types.includes('locality'))?.long_name;
      setValue(`${name}.city`, city);

      const state = place.address_components?.find((x) =>
        x.types.includes('administrative_area_level_1')
      )?.short_name;
      setValue(`${name}.state`, state);

      const zip = place.address_components?.find((x) =>
        x.types.includes('postal_code')
      )?.short_name;
      setValue(`${name}.zip`, zip);

      const latitude = place.geometry?.location?.lat();
      const longitude = place.geometry?.location?.lng();
      if (longitude && latitude) {
        setValue(`${name}.latitude`, latitude);
        setValue(`${name}.longitude`, longitude);

      }
      if (trigger) {
        [`${name}.address1`, `${name}.city`, `${name}.state`, `${name}.zip`].forEach((_name) => trigger(_name))
        if (longitude && latitude) {
          trigger(`${name}.latitude`);
          trigger(`${name}.longitude`);
        }
      }
    },
    options: {types: ['geocode'], fields: ['geometry.location', 'address_components']},
  });

  const dv = useMemo(() => {
    return getDefaultFunction ? getDefaultFunction(name, defaultValue) : defaultValue;
  }, [getDefaultFunction, defaultValue]);

  // prevent submitting form on enter
  useEffect(() => {
    //@ts-ignore
    const callback = (event) => {
      if (event.keyCode === 13) {
        event.preventDefault();
      }
    };
    if (ref.current) {
      (ref.current as HTMLInputElement).addEventListener('keydown', callback);
    }
    return () => {
      try {
        if (ref.current) (ref.current as HTMLInputElement).removeEventListener('keydown', callback);
      } catch (e) {
      }
    };
  }, [ref.current]);

  return (
    <div className={'smart-address'}>
      <strong>{label}</strong>
      {
        googleSearch && <TextBox
              control={control}
              disabled={disabled}
              label={'Address Search'}
              name={"search"} ref={ref}
          />
      }
      <Hidden
        name={`${name}.id`}
        control={control}
        disabled={disabled}
        defaultValue={dv?.id ?? undefined}
        getDefaultFunction={getDefaultFunction}
      />
      {showAddressType ?
        <Select
          name={`${name}.addressType`}
          label={'Address Type'}
          control={control}
          rules={rules}
          disabled={disabled}
          options={AddressTypeEnum}
          defaultValue={dv?.addressType ?? 'PHYSICAL'}
          getDefaultFunction={getDefaultFunction}/>
        : <Hidden
          name={`${name}.addressType`}
          control={control}
          disabled={disabled}
          defaultValue={dv?.addressType ?? 'PHYSICAL'}
          getDefaultFunction={getDefaultFunction}
        />}
      <Hidden
        name={`${name}.addressType`}
        control={control}
        rules={rules}
        disabled={disabled}
        defaultValue={dv?.addressType ?? 'PHYSICAL'}
        getDefaultFunction={getDefaultFunction}
      />
      <TextBox
        name={`${name}.address1`}
        label={'Street Address'}
        type={'string'}
        control={control}
        rules={rules}
        disabled={disabled}
        defaultValue={dv?.address1 ?? undefined}
        getDefaultFunction={getDefaultFunction}
      />

      <TextBox
        name={`${name}.address2`}
        label={'Address Line 2'}
        type={'string'}
        control={control}
        disabled={disabled}
        defaultValue={dv?.address2 ?? undefined}
        getDefaultFunction={getDefaultFunction}
      />

      <Row>
        <Col>
          <TextBox
            name={`${name}.city`}
            label={'City'}
            type={'string'}
            control={control}
            rules={rules}
            disabled={disabled}
            defaultValue={dv?.city ?? undefined}
            getDefaultFunction={getDefaultFunction}
          />
        </Col>

        <Col>
          <Select
            name={`${name}.state`}
            label={'State'}
            control={control}
            rules={rules}
            options={statesObject}
            disabled={disabled}
            defaultValue={dv?.state ?? undefined}
            getDefaultFunction={getDefaultFunction}
          />
        </Col>
        <Col>
          <TextBox
            name={`${name}.zip`}
            label={'Zip'}
            type={'zip'}
            control={control}
            rules={{
              ...rules,
              validate: {
                ...rules?.validate,
                zipValidation: zipValidator
              }
            }}
            disabled={disabled}
            defaultValue={dv?.zip ?? undefined}
            getDefaultFunction={getDefaultFunction}
          />
        </Col>
      </Row>
    </div>
  );
};