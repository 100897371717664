import "./polyfills";
import React from 'react';
import ReactDOM from 'react-dom';
import {RecoilRoot} from 'recoil';
import initializeStatistics from '@common/utils/statistics';
import App from './ui/App_v2';

ReactDOM.render(
	<React.StrictMode>
		<RecoilRoot>
			<App />
		</RecoilRoot>
	</React.StrictMode>,
	document.getElementById('root')
);

initializeStatistics();
