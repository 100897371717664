import React, {useCallback} from 'react';
import Pressable from "@admin/components/buttons/Pressable";
import FontAwesomeIcon from "@admin/components/icons/FontAwesomeIcon";

const styles = {marginRight: '10px'};

const NavigationButton = () => {

	const handleOnBackClick = useCallback(() => {
		window.history.back();
	}, []);

	return (
		<div style={styles}>
			<Pressable className="btn btn-primary" onClick={handleOnBackClick}>
				<FontAwesomeIcon icon="chevron-left"/>
			</Pressable>
		</div>
	);
};

export default NavigationButton;
