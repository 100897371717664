import React, {Fragment} from "react";
import HornetPropertiesMap from "@legacy/views/InvestmentListView/components/HornetPropertiesMap";

const FundMap = () => {

  return (
    <Fragment>
      <section className="content-header">
        <div className="header-title">
          <h1>Fund Map</h1>
          <small>Investor Fund Map</small>
        </div>
      </section>
      <section className="content">
        <div className="row">
          <div className="col-12">
            <div className="card">
              <HornetPropertiesMap/>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );

};

export default FundMap;