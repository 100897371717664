import {Chat, ChatContact, ChatEntity, ChatLoan, ChatObject, ITask, Message, Task} from '@interfaces/task';

import {
  chatsResultsState,
  chatsState,
  companyListState,
  createdDrawRequestCountState,
  getChatsStateCurrent,
  isLoadingState,
  isRefreshingTaskDetailsState,
  loanListState,
  messageResultState,
  notificationSidebarIsOpenState,
  propertyListState,
  refreshAnnouncementsState,
  selectedChatState,
  selectedTaskDetailState,
  selectedTaskState,
  selectedTopMenuItemState,
  selectedUserOrCompanyState,
  submittedBudgetCountState,
  tasksMetaState,
  tasksState,
  transfersState,
  userBudgetNotificationState,
  userListState,
  userTypingChatsGlobalState
} from './globalState';
import isAdminState from "@state/globalState/isAdminState";
import Transfer from '@interfaces/Transfer';
import getContactList from '@hornet-api/task/getContactList';
import getCompanyList from '@hornet-api/task/getCompanyList';
import getTransactions from '@hornet-api/banking/getTransactions';
import getChatObject, {GetChatOptions} from "@hornet-api/task/getChatObject";
import moment from 'moment';
import getMessages, {GetMessageOptions} from "@hornet-api/task/getMessages";
import {getComparatorByKey, getQueryParams, objCompare, updateParams} from "@common/basic";
import ACHStatusEnum from "@admin/enum/ACHStatusEnum";
import {getPaginateTasks} from "@hornet-api/task/getTasks";
import {contactState, titleOfficeListState} from "@admin-ui/pages/ContactPage/globalState";
import listTitleOfficeOptions from "@hornet-api/company/listTitleOfficeOptions";
import {getSubmittedBudgetCount} from "@hornet-api/loans/admin/budget";
import {getCreatedDrawRequestCount} from "@hornet-api/loans/admin/drawRequest";
import {getUserBudgetNotification} from "@hornet-api/loans/user/budget/notifications";
import {alertApiErrors} from "@common/errors";
import {loadingRelease, loadingTrigger} from "@components/LoadingOverlay";
import {updateContact} from "@admin-ui/pages/ContactPage/utils";
import {fetchUser2FA} from "@hornet-api/twoFactorAuth/user/fetchUser2FA";
import {twoFactorAuthState} from "@legacy/views/UserProfileLegacy/TwoFactorAuthenticationModal";
import {addAlert} from "@components/Alert";
import {createGlobalState} from "react-global-hooks";
import {loadOneTimeLoanPayment, payerTokenState} from "@pages/OneTimeLoanPaymentPage/global";
import {getLpaList} from "@hornet-api/loanParticipationAgreement/getLpaList";
import {getPropertyListOptions} from "@hornet-api/properties/getPropertyListOptions";
import {getInvestorData} from "@hornet-api/investor/getInvestorData";
import {
  formatAndSetAch,
  formatAndSetAmbReferral,
  formatAndSetBpa,
  formatAndSetCompany,
  formatAndSetContact,
  formatAndSetEmailLogs,
  formatAndSetFund,
  formatAndSetLoan,
  formatAndSetLpa,
  formatAndSetProperty,
  formatAndSetRecentSearch,
  formatAndSetTask,
} from "@admin-ui/pages/SearchPage/constant";
import {getGlobalLoanList} from "@hornet-api/task/getGlobalLoanList";
import {getAchListData} from "@hornet-api/transactions/getAchListData";
import {getBorrowerApplicationListData} from "@hornet-api/borrower/getBorrowerApplicationListData";
import {getAmbassadorListData} from "@hornet-api/ambassador/getAmbassadorListData";
import {getTaskListData} from "@hornet-api/task/getTaskListData";
import {getEmailLogData} from "@hornet-api/emailLog/getEmailLogData";
import {getRecentGlobalSearchList} from "@hornet-api/recentGlobalSearch/getRecentGlobalSearchList";
import getTaskById from "@hornet-api/task/getTaskById";
import getAdminApiPath from "@hornet-api/getAdminApiPath";
import axios from "axios";
import {UserTypingChat, UserTypingChatWithTimeout} from "@components/NotificationSidebar/types";

export const typingDurationTime = 10000;
export const hideTypingTime = typingDurationTime + 2000;
/**
 * Keeps track of the loading state to keep loading open until all triggers are set false
 * @param {boolean} state - boolean - The state of the loading indicator.
 */
export const setIsLoading = (state: boolean) => {
  if (state) {
    isLoadingState.set(isLoadingState.get() + 1);
  } else {
    const totalLoaders = isLoadingState.get() - 1
    isLoadingState.set(totalLoaders < 0 ? 0 : totalLoaders);
  }
};

/**
 * It takes a chat object and returns a string of the chat's members' names
 * @param {Chat} chat - Chat - the chat object
 */
export const getChatName = (chat: Chat) => {
  const isAdmin = isAdminState.get();

  if ('contactName' in chat) {
    return chat.contactName;
  }

  if ('entityName' in chat) {
    return chat.entityName;
  }

  if ('loanName' in chat) {
    return chat.loanName;
  }

  const normalMembers = chat.participants
    .map((member) => {
      if (isAdmin && !member.isAdmin) {
        return member.name;
      }
      if (!member.isAdmin) {
        return member.name;
      }
    })
    .filter((x) => x);
  if (isAdmin) {
    return normalMembers.join(', ');
  } else {
    // add Hornet team to chat
    return ['Hornet Team', ...normalMembers].join(', ');
  }
};

/**
 * > If the user is an admin, return the company name or contact name, otherwise return null
 * @param {Task} task - Task - this is the task object that is passed in from the template
 */
export const getTaskOwnerName = (task: Task) => {
  const userList = userListState.get();
  if ('contact' in task && userList) {
    const user = userList.find((u) => u.id === task.contact);
    if (user) {
      return user.name;
    }
  }
  const companyList = companyListState.get();
  if ('entity' in task && companyList) {
    //@ts-ignore
    const company = companyList.find((c) => c.id === task.entity);
    if (company) {
      //@ts-ignore
      return company.name;
    }
  }
  return null;
};

export const getNumChatNotifications = (chat: Chat) => {
  if (isAdminState.get()) {
    if (chat.isResolved) {
      return 0;
    } else {
      return 1;
    }
  } else {
    return chat.unread;
  }
};

export const getNumTaskNotifications = (task: ITask): number => {
  if (isAdminState.get()) {
    if (task.isResolved) {
      return 0;
    } else {
      return 1;
    }
  } else {
    // return task.chat.unread;
    const activeCount = task.taskStatus === 'ACTIVE' ? 1 : 0;
    return task.unreadMessages + activeCount;
  }
};

export const refreshTaskPageState = createGlobalState<number>(0);

export const triggerTaskRefresh = () => {
  tasksMetaState.set({total: 0, totalUnresolvedCount: 0, page: 0});
  refreshTaskPageState.set(new Date().getTime());
}

export const getLastChatMessage = (messages: Message[]) => {
  // return text from last chat message
  if (!messages || messages.length === 0) {
    return null;
  }
  for (let i = messages.length - 1; i >= 0; i--) {
    // only return if it is a message
    if (messages[i].type === 'CHAT') {
      return messages[i].content;
    }
  }
  return null;
};

export const getUserCompanySelectGroups = () => {
  const userList = userListState.get();
  const companyList = companyListState.get();
  return [
    {
      label: 'Users',
      options: userList?.map((user) => {
        return {
          value: `CONTACT-${user.id}`,
          label: user.name,
          disabled: !user.userId
        };
      }) || [],
    },
    {
      label: 'Entities',
      options: companyList
        ? companyList?.map((company) => {
          return {
            value: `ENTITY-${company.id}`,
            label: company.name,
          };
        })
        : [],
    },
  ];
};
export const getContactOptionById = (id: number) => {
  const contactList = userListState.get();
  const foundContact = contactList?.find((contact) => contact.id === id);
  if (foundContact) {
    return {
      value: `contact|${foundContact.id}`,
      label: foundContact.name,
      disabled: !foundContact.userId
    };
  }
}

export const getLoanOptionById = (id: string) => {
  const loanList = loanListState.get();
  const foundLoan = loanList?.find((loan) => loan.id === id && loan.loanStage !== "CLOSED");
  if (foundLoan) {
    return {
      value: `loan|${foundLoan.id}`,
      label: `${foundLoan.id} (${foundLoan.primaryProperty || ''})`,
    };
  }
}
export const getUserCompanyLoanSelectGroups = () => {
  const userList = userListState.get();
  // const companyList = companyListState.get();
  const loanList = loanListState.get()?.filter(loan => loan.loanStage !== "CLOSED") || null;
  return [
    {
      label: 'Users',
      options: userList?.map((user) => {
        return {
          value: `contact|${user.id}`,
          label: user.name,
          disabled: !user.userId
        };
      }) || [],
    },
    // {
    //   label: 'Companies',
    //   options: companyList
    //     ? companyList?.map((company) => {
    //       return {
    //         value: `company|${company.id}`,
    //         label: company.name,
    //       };
    //     })
    //     : [],
    // },
    {
      label: 'Loans',
      options: loanList
        ? loanList?.map((loan) => {
          return {
            value: `loan|${loan.id}`,
            label: `${loan.id} (${loan.primaryProperty || ''})`,
          };
        })
        : [],
    }
  ];
};

export const getLoanSelectOptions = () => {
  const loanList = loanListState.get();
  return [{value: '', label: '-- Please Select --'}].concat(loanList?.map((loan) => {
    return {
      value: loan.id,
      label: `${loan.id} (${loan.primaryProperty})`,
    };
  }) || []);
};

export const refreshLoan = () => {
  // for global modal
  getGlobalLoanList().then(formatAndSetLoan)
}

export const refreshContactList = (shouldRefreshAmbassador: boolean | void = true) => {
  getContactList().then((contactList) => {
    contactList = contactList.filter((contact) => contact?.name).sort(getComparatorByKey('name'));
    userListState.set(contactList);
    formatAndSetContact(contactList);
    if (shouldRefreshAmbassador)
      refreshAmbassador()
  });
}

export const refreshCompanyList = (shouldRefreshAmbassador: boolean | void = true) => {
  getCompanyList().then((companyList) => {
    companyList = companyList.filter((company) => company?.name).sort(getComparatorByKey('name'));
    companyListState.set(companyList);
    formatAndSetCompany(companyList);
    if (shouldRefreshAmbassador)
      refreshAmbassador()
  });
}

/**
 * It loads the user and company lists if they haven't been loaded yet, and if the user is an admin
 */
export const updateUserAndCompanyLists = () => {

  // load the users and company list
  if (isAdminState.get()) {
    let callRefreshAmbassador = false
    if (userListState.get() === null) {
      callRefreshAmbassador = true
      refreshContactList(false);
    }
    if (companyListState.get() === null) {
      callRefreshAmbassador = true
      refreshCompanyList(false);
    }
    if (callRefreshAmbassador) {
      refreshAmbassador()
    }
    if (loanListState.get() === null) {
      refreshLoan();
    }
    if(titleOfficeListState.get() === null){
      listTitleOfficeOptions().then((titleOffices) => {
        titleOfficeListState.set(titleOffices);
      });
    }
  }
};

export const refreshFundList = async () => {
  // This is used for Global Search Modal
  const fundList = await getInvestorData();
  return formatAndSetFund(fundList);
}

export const refreshPropertyList = () => {
  getPropertyListOptions().then((propertyList) => {
    propertyList = propertyList.filter(x => x).sort(getComparatorByKey('id'));
    propertyListState.set(propertyList);
    formatAndSetProperty(propertyList);
  })
}

export const refreshLpaList = () => {
  getLpaList().then(formatAndSetLpa)
}
export const refreshAchList = () => {
  getAchListData().then(formatAndSetAch)
}

export const refreshRecentSearch = () => {
  getRecentGlobalSearchList().then(formatAndSetRecentSearch)
}
export const refreshBPA = () => {
  getBorrowerApplicationListData().then(formatAndSetBpa)
}
export const refreshAmbassador = () => {
  getAmbassadorListData().then(formatAndSetAmbReferral)
}
export const refreshTask = () => {
  getTaskListData().then(formatAndSetTask)
}
export const refreshEmailLog = () => {
  getEmailLogData().then(formatAndSetEmailLogs)
}
export const refreshData = () => {
  if (isAdminState.get()) {
    refreshAchList();
    refreshBPA();
    refreshTask();
    refreshEmailLog();
    refreshFundList();
    refreshPropertyList();
    refreshLpaList();
  }
}
export const sortChatObject = (chatObj: ChatObject): ChatObject => {
  const dateSort = (a: Chat, b: Chat) => {
    const A = moment(a.lastUpdated);
    const B = moment(b.lastUpdated);
    if (A.isBefore(B)) return 1;
    if (B.isBefore(A)) return -1;
    return 0;
  }
  return {
    announcementBorrower: chatObj.announcementBorrower,
    announcementInvestor: chatObj.announcementInvestor,
    contactChat: chatObj.contactChat ? chatObj.contactChat : undefined,
    contactChats: chatObj.contactChats ? [...chatObj.contactChats].sort(dateSort) : undefined,
    loanChats: chatObj.loanChats ? [...chatObj.loanChats].sort(dateSort) : undefined,
    // entityChats: chatObj.entityChats ? [...chatObj.entityChats].sort(dateSort) : [],
  } as ChatObject;
}

/**
 * Updates the global state for the task details for the selected task
 * @param [showLoading=true] - boolean - whether to show the loading spinner or not
 */
export const refreshTaskDetails = async (showLoading = true) => {
  if (isRefreshingTaskDetailsState.get()) {
    return
  }
  isRefreshingTaskDetailsState.set(true);
  const selectedTask = selectedTaskState.get();
  const isAdmin = isAdminState.get();
  if (selectedTask) {
    if (showLoading) setIsLoading(true);
    try {
      let selTask = tasksState.get()?.find((x) => x.id === selectedTask);

      // If task is not found in the task list (paginated now ONLY for admin), let's find it from the server
      if (!selTask && isAdmin) {
        const response = await getPaginateTasks({id: `=${selectedTask}`});
        if (response.data.length > 0) {
          selTask = response.data[0]
        }
      }
      if (selTask) {
        const t = await getTaskById(selectedTask);
        selectedTaskDetailState.set(t);
      } else if (isAdmin) {
        selectedTaskState.set(null);
        addAlert({
          content: 'Task has been deleted.',
          type: 'danger',
        });
      }
    } catch (e) {
      if (isAdmin) {
        selectedTaskState.set(null);
        addAlert({
          content: 'Problem loading task details, please try again.',
          type: 'danger',
        });
      }
    }
    if (showLoading) setIsLoading(false);
  } else {
    // task not selected null it out
    selectedTaskDetailState.set(null);
  }
  isRefreshingTaskDetailsState.set(false);
};

export const refreshAnnouncements = () => {
  refreshAnnouncementsState.set(Date.now());
}

export const refreshUserBudgetNotificationCount = () => {
  const t = loadingTrigger();
  getUserBudgetNotification()
    .then(userBudgetNotificationState.set)
    .catch(alertApiErrors)
    .finally(() => loadingRelease(t));
}

/**
 * Update the Contact Global State if Contact Details Page is opened by Admin
 *
 * @param contactId {@link - number}
 */
export const refreshAdminContactInfo = (contactId: number) => {
  if (contactState.get()?.id === contactId) {
    updateContact(contactId).catch(alertApiErrors)
  }
}

export const refreshUser2FAInfo = () => {
  const t = loadingTrigger();
  fetchUser2FA()
    .then(twoFactorAuthState.set)
    .finally(() => {
      loadingRelease(t)
      addAlert({
        type: 'info',
        content: '2FA is updated by Admin'
      });
    });
}

export const refreshChatObject = async (showLoading = true, openUserChat = false) => {
  if (showLoading) setIsLoading(true);
  try {
    const isAdmin = isAdminState.get();
    const opts: GetChatOptions = {isAdmin: isAdmin};
    const optsSearch = {...opts};

    const selectedUserOrCompany = selectedUserOrCompanyState.get();
    const selectedChat = selectedChatState.get();
    if (selectedUserOrCompany) {
      const [type, idString] = selectedUserOrCompany.value.split('|');
      optsSearch.contactId = type === 'contact' ? Number(idString) : undefined;
      optsSearch.companyId = type === 'company' ? Number(idString) : undefined;
      optsSearch.loanId = type === 'loan' ? idString : undefined;
    }

    if (selectedChat) {
      optsSearch.selectedChat = selectedChat
    }

    if (selectedUserOrCompany || selectedChat) {
      const s = await getChatObject(optsSearch);
      if (openUserChat) {
          selectedChatState.set(s?.contactChats?.[0]?.id || s?.loanChats?.[0]?.id|| null);
      }
      chatsResultsState.set(sortChatObject(s));
    } else if (chatsResultsState.get() !== null) {
      chatsResultsState.set(null);
    }
    // get the users tasks
    const t = await getChatObject(opts);
    chatsState.set(sortChatObject(t));
  } catch (e) {
    console.error('#### Error refreshing chat object: ', e);
  }
  if (showLoading) setIsLoading(false);
};

export const getSelectedChatItem = () => {
  const selectedChat = selectedChatState.get();
  const chatObj = getChatsStateCurrent();
  if (selectedChat && chatObj) {
    if (chatObj?.announcementBorrower?.id === selectedChat) return chatObj.announcementBorrower;
    if (chatObj?.announcementInvestor?.id === selectedChat) return chatObj.announcementInvestor;
    if (chatObj?.contactChat?.id === selectedChat) return chatObj.contactChat;

    const contactChat = chatObj.contactChats?.find(x => x.id === selectedChat);
    if (contactChat) return contactChat;

    // const entityChat = chatObj.entityChats?.find(x => x.id === selectedChat);
    // if (entityChat) return entityChat;
    const loanChats = chatObj.loanChats?.find(x => x.id === selectedChat);
    if (loanChats) return loanChats;
    return null;
  } else {
    return null;
  }
}

export const getIsAnnouncement = () => {
  const selectedChatItem = getSelectedChatItem();
  const chatObj = getChatsStateCurrent();
  if (selectedChatItem && chatObj) {
    if(chatObj.announcementInvestor?.id === selectedChatItem.id) return 'investor';
    if(chatObj.announcementBorrower?.id === selectedChatItem.id) return 'borrower';
    return null;
  }
  return null;
}

export const refreshChatDetails = async (showLoading = true) => {
  const isAdmin = isAdminState.get();
  const selectedChatItem = getSelectedChatItem();
  if (selectedChatItem === null) {
    if (messageResultState.get()) {
      messageResultState.set(null);
    }
    return;
  }
  if (showLoading) {
    setIsLoading(true);
  }
  try {
    let opts: GetMessageOptions = {
      isAnnouncement: getIsAnnouncement()
    };
    if (isAdmin) {
      if ('contact' in selectedChatItem) {
        opts.contactId = (selectedChatItem as ChatContact).contact;
      } else if ('entity' in selectedChatItem) {
        opts.companyId = (selectedChatItem as ChatEntity).entity;
      } else if ('loan' in selectedChatItem) {
        opts.loanId = (selectedChatItem as ChatLoan).loan;
      }
    } else if ('entity' in selectedChatItem) {
      opts.contactCompanyId = (selectedChatItem as ChatEntity).entity;
    } else if ('loan' in selectedChatItem) {
      opts.loanId = (selectedChatItem as ChatLoan).loan;
    }
    const m = await getMessages(opts);
    messageResultState.set(m);
  } catch (e) {
    console.error('#### Error refreshing chat details', e);
  }
  if (showLoading) {
    setIsLoading(false);
  }
};

/**
 * It gets the transactions from the server and updates the transfersState
 */
export const refreshTransactions = async () => {
  const isAdmin = isAdminState.get();
  // Nothing to do for admin or a payer (public access)
  const token = payerTokenState.get();
  if (isAdmin) return;
  if (token) {
    await loadOneTimeLoanPayment();
    return;
  }
  const transactions = await getTransactions();
  if (transactions) {
    transfersState.set(transactions);
  }
};

/**
 * Fetches Pending Authorization budget count
 * Updates the global state which is used in sidebarLinkObject
 */
export const refreshBudgetCount = () => {
  getSubmittedBudgetCount().then(res => {
    submittedBudgetCountState.set(res.total);
  });
}

/**
 * Fetches Created draw request count
 * Updates the global state which is used in sidebarLinkObject
 */
export const refreshDrawRequestCount = () => {
  getCreatedDrawRequestCount().then(res => {
    createdDrawRequestCountState.set(res.total);
  });
}

export const countTransferNotification = (status: keyof typeof ACHStatusEnum) => {
  return ['WIRE_TRANSFER_PENDING', 'ACH_PENDING_AUTHORIZATION'].includes(status)
}

export const getNumTransferNotifications = (transfers: Transfer[]) => {
  if (!transfers) return 0;
  return transfers.filter(
    (x) => countTransferNotification(x.transactionStatus)
  ).length;
};

export const openChat = () => {
  selectedTopMenuItemState.set('messages');
  notificationSidebarIsOpenState.set(true);
}

export const openTasks = () => {
  selectedTopMenuItemState.set('tasks');
  notificationSidebarIsOpenState.set(true);
}

export const activateUserChat = () => {
  openChat();
  const chatObj = getChatsStateCurrent();
  if(chatObj?.contactChat){
    const contactChatId = chatObj.contactChat.id;
    selectedChatState.set(contactChatId);
    console.log('selectedChatState', selectedChatState.get())
  } else {
    console.log('nope', chatObj)
  }
}

export const activateContactChat = (contactId: number) => {
  openChat();
  const option = getContactOptionById(contactId);
  if (!option) {
    console.error('Activating Contact Chat - Contact not found with contactId: ', contactId);
    return;
  }
  selectedUserOrCompanyState.set(option || null)
  refreshChatObject(false, true)
}

export const activateLoanChat = (loanId: string) => {
  openChat();
  const option = getLoanOptionById(loanId);
  if (!option) {
    console.error('Activating Loan Chat - Loan not found with loanId: ', loanId);
    return;
  }
  selectedUserOrCompanyState.set(option || null)
  refreshChatObject(false, true)
}

export const activateTask = (taskId: number) => {
  openTasks();
  selectedTaskState.set(taskId);
}

export const handleChatQueryParams = () => {
  const params = getQueryParams();
  let didChange = false

  if('chatId' in params) {
    openChat();
    // isLoadingState.set(true);
    selectedChatState.set(Number(params.chatId));
    console.log('opening chat id ' + params.chatId)
    delete  params.chatId;
    didChange = true;
  }

  if('taskId' in params) {
    openTasks();
    // isLoadingState.set(true);
    selectedTaskState.set(Number(params.taskId));
    delete  params.taskId;
    didChange = true;
  }

  if(didChange) {
    updateParams(params);
  }
}

export const deleteChat = async (messageId: number) => {
  setIsLoading(true);
  const url = `${getAdminApiPath()}/chat/message/${messageId}`;
  await axios.delete(url);
  setIsLoading(false);
}


export const updateUserTypingChatsState = (newTypingUser?: UserTypingChat) => {
  if (!newTypingUser) {
    return
  }

  const timeoutId = setTimeout(() => {
    removeFromUserTypingChatsState(newTypingUser);
  }, hideTypingTime);

  const updatedState = removeFromUsersTypingChats(userTypingChatsGlobalState.get(), newTypingUser);

  updatedState.push({...newTypingUser, timeoutId});

  userTypingChatsGlobalState.set(updatedState);
};

const removeFromUsersTypingChats = (oldState: UserTypingChatWithTimeout[], toBeRemoved: UserTypingChat) => {
  return oldState
    .filter((typingState) => {
      if (objCompare(typingState, {...toBeRemoved}, ['timeoutId'])) {
        if (typingState.timeoutId) {
          clearTimeout(typingState.timeoutId)
        }
        return false
      }
      return true
    });
}

// Function to remove a user from the typing state
export const removeFromUserTypingChatsState = (userToRemove?: UserTypingChat) => {
  if (!userToRemove) {
    return
  }

  userTypingChatsGlobalState.set(removeFromUsersTypingChats(userTypingChatsGlobalState.get(), userToRemove));
};