import React, {ReactNode} from 'react';
import RedBox from './RedBox';

interface ErrorBoundaryProps {
	children: ReactNode;
}

interface ErrorBoundaryState {
	hasError: boolean;
}

export default class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
	constructor(props: ErrorBoundaryProps) {
		super(props);
		this.state = {
			hasError: false,
		};
	}

	static getDerivedStateFromError(): ErrorBoundaryState {
		return {hasError: true};
	}

	componentDidCatch(error: Error, info: React.ErrorInfo): void {
		console.error("Error caught by ErrorBoundary:", error, info);
	}

	render() {
		if (this.state.hasError) {
			return <RedBox/>;
		}
		return this.props.children;
	}
}
