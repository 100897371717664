import axios from "axios";
import getUserApiPath from "@hornet-api/getUserApiPath";
import {NotaryDocumentFormData} from "@admin-ui/pages/OneNotaryPage/components/OneNotaryDetails/AddDocumentModal";
import {IOneNotary, IOneNotaryDocument} from "@interfaces/one-notary";
import {OneNotaryDocumentTypeEnum} from "@interfaces/GeneratedEnums";
import {INotarySessionLight} from "@legacy/views/UserNotary/tableData";

const checkNotaryEligibility = async () => {
  const url = `${getUserApiPath()}/oneNotary/eligibility`;
  const res = await axios.get<{ isEligible: boolean }>(url);
  return res.data.isEligible;
}

const getSessionList = async () => {
  const url = `${getUserApiPath()}/oneNotary`;
  const res = await axios.get<INotarySessionLight[]>(url);
  return res.data;
};

const createNewSession = async (isPrivate: boolean) => {
  const url = `${getUserApiPath()}/oneNotary`;
  const res = await axios.post<IOneNotary>(url, {isPrivate});
  return res.data;
};

const initSession = async (sessionId: string, createTaskFlag: boolean) => {
  const url = `${getUserApiPath()}/oneNotary/${encodeURIComponent(sessionId)}/init`;
  const res = await axios.post(url, {createTaskFlag});
  return res.data;
};

const cancelSession = async (sessionId: string) => {
  const url = `${getUserApiPath()}/oneNotary/${encodeURIComponent(sessionId)}/cancelSession`;
  const res = await axios.post(url);
  return res.data;
};

const addDocument = async (sessionId: string, notaryDocumentFormData: NotaryDocumentFormData) => {
  const formData = new FormData();
  formData.append('file', notaryDocumentFormData.file!);

  const url = `${getUserApiPath()}/oneNotary/${encodeURIComponent(sessionId)}/documents`;
  const res = await axios.post<IOneNotaryDocument>(url, formData);
  return res.data;
};

const getDocumentList = async (sessionId: string) => {
  const url = `${getUserApiPath()}/oneNotary/${encodeURIComponent(sessionId)}/documents`;
  return axios.get<IOneNotaryDocument[]>(url).then(res => res.data);
};

const getInvitationInfoForPrimarySigner = async (sessionId: string) => {
  const url = `${getUserApiPath()}/oneNotary/${encodeURIComponent(sessionId)}/participants/invitation/primary`;
  const res = await axios.get<{ link: string; }>(url);
  return res.data;
};

const downloadDocumentsUrl = (sessionId: string, documentType: keyof typeof OneNotaryDocumentTypeEnum) => {
  return `${getUserApiPath()}/oneNotary/${encodeURIComponent(sessionId)}/documents/download/${documentType}`;
}


export {
  checkNotaryEligibility,
  getSessionList,
  createNewSession,
  initSession,
  cancelSession,
  addDocument,
  getDocumentList,
  getInvitationInfoForPrimarySigner,
  downloadDocumentsUrl
}